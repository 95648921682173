div.ant-row {
  /* background-color: red; */
  padding-top: 0.5rem;
}
div.ant-col {
  /* background-color: orange; */
}
div.ant-card-body {
  /* background-color: blueviolet; */
  padding: 0;
}

.ant-descriptions-item {
  padding-bottom: 0 !important;
}

button#previous-page.ant-btn-link.ant-btn-lg {
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  height: auto;
}
#target-info {
}

#target-info .ant-row {
  padding: 0;
  margin: 0;
}

#target-info .ant-descriptions-item-label {
  text-align: right;
}

#target-info .ant-descriptions-item-label,
#target-info .ant-descriptions-item-content {
  font-size: 1rem;
  padding: 0.1rem 1rem;
}

#target-temp {
  text-align: center;
  background-color: black;
  color: white;
}
#target-temp .temp {
  padding-top: 2rem;
  font-size: 2rem;
}

#target-temp .when,
#target-temp .timeAgo,
#target-temp .timestamp {
  font-style: italic;
  font-size: medium;
}

#target-temp .status {
  margin-top: 2rem;
  border: 0.5rem;
  border-color: var(--color-normal);
  border-style: solid;
}

#target-temp .status.critical {
  border-color: var(--color-critical);
}

#target-temp .status.warning {
  border-color: var(--color-warning);
}
#temperature-chart .ant-alert-message {
  font-size: 0.5rem;
}

#target .chart-title {
  padding: 0;
  margin: 0;
  text-align: center;
}
#target .click-point {
  background-color: white;
  color: black;
  border: 0;
  padding-top: 0;
  margin-top: 0;
  line-height: 0;
  font-style: italic;
  font-size: smaller;
}
#target-controls {
  padding: 2px;
}

#target-controls .ant-picker-separator {
  display: none;
}

.ant-image-img,
.site-map {
  border: 1px solid black !important;
  margin: 0 0 0 0;
  width: auto;
  height: 30vh;
  max-width: fit-content;
}

figure {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.ant-image-img,
.target-location,
figure .target-temp-image,
figure .targert-ref-image,
figure .target-location {
  border: 1px solid black !important;
  margin: 0 0 0 0;
  width: auto;
  height: 35vh;
}

.target-location {
  margin-bottom: 14px;
}

figcaption {
  height: 0.5rem;
}

.placeholder {
  line-height: 35vh;
}
